<template>
	<div>
		<a-spin :spinning="loading">
			<div class="ft20 cl-main ftw600">积分设置</div>
			
			<div class="mt20">
				<div class="bg-w pd40" style="min-height: 800px;">
					<div>
						<div class="ft14 ftw500 cl-info">积分余额抵扣设置</div>
						<div class="mt16">
							<div class="flex alcenter">
								<div class="ft14 ftw500 cl-main">100积分</div>
								<div class="ft14 ftw500 cl-notice mr10 ml10">=</div>
								<div style="position: relative;">
									<a-input-number v-model="form.integral_exchange_balance" style="width: 130px;" :precision="2" :min="0" placeholder="请输入"/>
									<div class="setting-integral-addon">元</div>
								</div>
							</div>
						</div>
					</div>
					
					<div class="mt24">
						<div class="ft14 ftw500 cl-info">消费送积分比例</div>
						<div class="mt16">
							<div class="flex alcenter">
								<div style="position: relative;">
									<a-input-number v-model="form.recharge_give_integral_rate" style="width: 130px;" :precision="2" :min="0" placeholder="请输入"/>
									<div class="setting-integral-addon">%</div>
								</div>
								
								<div class="ft14 ftw500 cl-info ml16">如比例是80%，那么消费100元，即送会员80积分（余额支付不返积分，余额充值自动返）</div>
							</div>
						</div>
					</div>
					
					<div class="mt24">
						<div class="ft14 ftw500 cl-info">是否过期</div>
						<div class="mt16">
							<a-radio-group v-model="form.is_expire">
								<a-radio :value="1">
									是
								</a-radio>
								<a-radio :value="0">
									否
								</a-radio>
							</a-radio-group>
						</div>
					</div>
					
					<div class="mt24" v-if="form.is_expire==1">
						<div class="flex alcenter">
							<div class="ft14 ftw500 cl-info">过期时间</div>
							<div class="ft14 ftw400 cl-notice">(默认积分有效期为1年，不过期为10年)</div>
						</div>
						<div class="mt16 flex">
							<div  style="position: relative;">
								<a-input-number v-model="form.valid_year" style="width:300px;" :precision="0" :min="0" placeholder="默认积分有效期为1年，不过期为10年"/>
								<div class="setting-integral-addon">年</div>
							</div>
						</div>
					</div>
					
					
					
					<div class="mt30">
						<a-button type="primary" :loading="confirmLoading" @click="save()">保存</a-button>
					</div>
				</div>
			</div>
		</a-spin>
	</div>
</template>

<script>
	export default{
		data(){
			return{
				loading:false,
				confirmLoading:false,
				form:{
					integral_exchange_balance:'',
					recharge_give_integral_rate:'',
					is_expire:1,
					valid_year:'',
				}
			}
		},
		created() {
			this.loaddata();
		},
		methods:{
			loaddata(){
				if(this.loading==true) return;
				this.loading=true;
				this.$http.api('admin/showIntegralSetting').then(res=>{
					this.form=res.detail;
					this.loading=false;
				}).catch(res=>{
					console.log(res);
					this.loading=false;
				})
			},
			
			save(){
				if(this.confirmLoading==true) return;
				this.confirmLoading=true;
				this.$http.api('admin/saveIntegralSetting',{
					integral_exchange_balance:this.form.integral_exchange_balance,
					recharge_give_integral_rate:this.form.recharge_give_integral_rate,
					is_expire:this.form.is_expire,
					valid_year:this.form.valid_year,
				}).then(res=>{
					this.$message.success('保存成功',1,()=>{
						this.confirmLoading=false;
						this.loaddata();
					})
				}).catch(res=>{
					console.log(res);
					this.confirmLoading=false;
				})
			},
		}
	}
</script>

<style>
	.setting-integral-addon{
		position: absolute;
		top:0;
		right:26px;
		height: 32px;
		line-height: 32px;
		
		font-size: 14px;
		font-weight: 500;
		color: #A6AEC2;
	}
</style>
